var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY_NAME'),"label-for":"id-companyname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-companyname","maxlength":"50","placeholder":_vm.$t('COMPANY_NAME')},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ADDRESS_LINE_1'),"label-for":"id-addressLine-1"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ADDRESS_LINE_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-addressLine-1","maxlength":"45","placeholder":_vm.$t('ADDRESS_LINE_1')},model:{value:(_vm.company.addressLine1),callback:function ($$v) {_vm.$set(_vm.company, "addressLine1", $$v)},expression:"company.addressLine1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ADDRESS_LINE_2'),"label-for":"id-addressLine-1"}},[_c('b-form-input',{attrs:{"id":"id-addressLine-2","maxlength":"45","placeholder":_vm.$t('ADDRESS_LINE_2')},model:{value:(_vm.company.addressLine2),callback:function ($$v) {_vm.$set(_vm.company, "addressLine2", $$v)},expression:"company.addressLine2"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('POST_CODE'),"label-for":"id-postcode"}},[_c('validation-provider',{attrs:{"name":_vm.$t('POST_CODE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-postcode","maxlength":"45","placeholder":_vm.$t('POST_CODE')},model:{value:(_vm.company.postcode),callback:function ($$v) {_vm.$set(_vm.company, "postcode", $$v)},expression:"company.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CITY'),"label-for":"id-city"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CITY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-city","maxlength":"45","placeholder":_vm.$t('CITY')},model:{value:(_vm.company.city),callback:function ($$v) {_vm.$set(_vm.company, "city", $$v)},expression:"company.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COUNTRY'),"label-for":"id-country"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COUNTRY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-country","maxlength":"45","placeholder":_vm.$t('COUNTRY')},model:{value:(_vm.company.country),callback:function ($$v) {_vm.$set(_vm.company, "country", $$v)},expression:"company.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CONTACTED_EMAIL'),"label-for":"id-contactedEmail"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CONTACTED_EMAIL'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-contactedEmail","maxlength":"45","placeholder":_vm.$t('CONTACTED_EMAIL')},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('PRICING_PLANS'),"label-for":"id-package"}},[_c('validation-provider',{attrs:{"name":_vm.$t('PRICING_PLANS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.packageOptions,"label":"name","clearable":false,"input-id":"id-package"},model:{value:(_vm.pricePackage),callback:function ($$v) {_vm.pricePackage=$$v},expression:"pricePackage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"checkbox-emailNotificationEnabled","name":"checkbox-emailNotificationEnabled"},model:{value:(_vm.company.emailNotificationEnabled),callback:function ($$v) {_vm.$set(_vm.company, "emailNotificationEnabled", $$v)},expression:"company.emailNotificationEnabled"}},[_vm._v(" "+_vm._s(_vm.$t('EMAIL_NOTIFICATION'))+" ")])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.company.id ? _vm.$t('BUTTON.UPDATE') : _vm.$t('BUTTON.ADD'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }